import '../styles/globals.scss'
import type {AppProps} from 'next/app'
import React, {useEffect} from 'react'
import Script from 'next/script'
import {useRouter} from 'next/router'
import {GTM_ID, pageview} from '../src/utils/gtm'
import {MantineProvider} from "@mantine/core";
import {Notifications} from '@mantine/notifications';
import { start } from '../src/lib/bugsnag'

import CookieConsent from "../src/components/cookie/CookieConsent";

// Custom Font için 11-17 ve 31. satırları açın
// import {Manrope} from "next/font/google";
//
// const manrope = Manrope({
//     subsets: ['latin'],
//     weight: ['200', '300', '400', '500', '600', '700', '800'],
//     variable: '--font-manrope',
// })

start();

function MyApp({Component, pageProps}: AppProps) {
    const router = useRouter()
    useEffect(() => {
        router.events.on('routeChangeComplete', pageview)
        return () => {
            router.events.off('routeChangeComplete', pageview)
        }
    }, [router.events])


    return (
        <MantineProvider>
            <main
                // className={manrope.className}
            >
                {/* Google Tag Manager - Global base code */}
                <Script
                    id="gtag-base"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
                    }}
                />
                <Notifications position={'top-right'}/>
                <Component {...pageProps} />
                <CookieConsent/>
            </main>
        </MantineProvider>
    )
}

export default MyApp
